//import Module
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import albumArray from "../data/album.json";

//import Style
import "../styles/home.css";

function NewBanner() {
  function bannerClose() {
    document.getElementsByClassName("homebanner")[0].style.display = "none";
  }

  return (
    <div className="homebanner">
      <img src="https://sekainoowari.jp/wp-content/uploads/2025/02/%E7%B8%AE%E5%B0%8Finstantradio_mainV_nyuukou1_cut-%E3%81%AE%E3%82%B3%E3%83%92%E3%82%9A%E3%83%BC-1280x1810.jpg" />
      <div className="hide" onClick={bannerClose}>
        X
      </div>
    </div>
  );
}

function NewestArticle(props) {
  return (
    <div className="NewestAlbum" id="newAlbums">
      <div>
        <img src={props.imgSrc} oncontextmenu="return false;" />
      </div>
      <div>
        <span>{props.albumTitle}</span>
      </div>
    </div>
  );
}

function Newest() {
  const { t } = useTranslation();

  const albumList = albumArray.albums.map((albums, index) => {
    return (
      <Link to={`/lyrics/${albums.url}`}>
        <NewestArticle
          imgSrc={albums.img}
          albumTitle={albums.title}
          albumArtists={albums.artist}
        />
      </Link>
    );
  });

  return (
    <div>
      <div className="hometitle">{t("newTitle")}</div>
      <div className="NewestSection" id="NewestSection">
        <div className="AlbumFive">{albumList.slice(0, 5)}</div>
      </div>
    </div>
  );
}

export { Newest, NewBanner };
